import React from "react";

export const selectColourStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {

            ...styles,
            borderRadius: 0,
            borderWidth: '0 0 0 1px',
            borderColor: isSelected ? 'green' : '#dfdfdf',
            marginTop: 5,
            backgroundColor: 'white',
            boxShadow: 'none',
            fontSize: '1.2rem',
            fontFamily: 'Panton, sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            color: 'green',
            '&:hover': {
                borderColor: '#679d2e'
            },
        }
    },
    singleValue: (styles) => {
        return {
            ...styles,
            color: '#3f4d55'
        }
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null : isSelected ? data.color : isFocused ? '#679d2e' : null,
            color: isDisabled
                ? null : isSelected ? '#c6c6c6' : isFocused ? 'white' : null,
            fontFamily: 'Panton, sans-serif',
            fontStyle: 'normal',
            fontSize: '1.2rem',
            fontWeight: 400,
            "&:active": {
                backgroundColor: "rgba(103,157,46, 0.5)"
            },
        };
    },
};


export const BetterspaceIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="440" height="120" viewBox="0 0 192 51" strokeLinejoin="round">
        <path
            d="M63.5 19.8c-1.7 0-3.2 0.6-3.8 0.8l0 8.6c0 0.4 0.1 0.5 0.5 0.7 0.7 0.3 1.7 0.5 2.9 0.5 2.6 0 3.8-1.2 3.8-3.7l0-3.5c0-2.2-1.1-3.4-3.5-3.4m-3.8-6.4l0 5.6c0.7-0.2 2.2-0.8 4-0.8 3.5 0 5 1.8 5 4.9l0 3.6c0 3.4-1.7 5.2-5.4 5.2 -1.9 0-3.4-0.4-4.3-0.8 -0.8-0.4-1-0.6-1-1.4l0-16.4c0-0.4 0.1-0.5 0.5-0.5l0.8 0c0.4 0 0.5 0.1 0.5 0.5"
            fill="#3f4d55"/>
        <path
            d="M77.3 19.7c-2.6 0-3.9 1.4-3.9 3.7l0 0.9 7.8 0 0-0.9c0-2.2-1.3-3.7-3.9-3.7m0-1.5c3.8 0 5.6 2.2 5.6 5.2l0 1.4c0 0.4-0.2 0.7-0.8 0.7l-8.7 0 0 1.5c0 2.1 1.3 3.5 3.8 3.5 2.1 0 3.3-0.8 4-2.1 0.2-0.3 0.4-0.4 0.7-0.2l0.5 0.3c0.3 0.2 0.4 0.4 0.2 0.7 -0.9 1.6-2.5 2.7-5.4 2.7 -3.8 0-5.4-2.1-5.4-5.1l0-3.3c0-3.3 1.9-5.3 5.6-5.3"
            fill="#3f4d55"/>
        <path
            d="M101.9 20.1c0.4 0 0.5-0.1 0.5-0.5l0-0.7c0-0.4-0.1-0.5-0.5-0.5l-3.6 0 0-2.6c0-0.4-0.1-0.5-0.5-0.5l-0.8 0c-0.4 0-0.5 0.1-0.5 0.5l0 2.6 -0.7 0 -1.6 0 -5.3 0 0-2.6c0-0.4-0.1-0.5-0.5-0.5l-0.8 0c-0.4 0-0.5 0.1-0.5 0.5l0 2.6 -2.4 0c-0.4 0-0.5 0.1-0.5 0.5l0 0.7c0 0.3 0.1 0.5 0.5 0.5l2.4 0 0 6.6c0 4.1 1.4 5.2 5.3 5.1 0.3 0 0.5-0.1 0.5-0.5l0-0.7c0-0.4-0.1-0.5-0.5-0.5 -2.7 0-3.6-0.6-3.6-3.6l0-6.6 7.7 0 0 6.6c0 4.1 1.4 5.2 5.3 5.1 0.3 0 0.5-0.1 0.5-0.5l0-0.7c0-0.4-0.1-0.5-0.5-0.5 -2.7 0-3.6-0.6-3.6-3.6l0-6.6 3.6 0Z"
            fill="#3f4d55"/>
        <path
            d="M110 19.7c-2.6 0-3.9 1.4-3.9 3.7l0 0.9 7.8 0 0-0.9c0-2.2-1.3-3.7-3.9-3.7m0-1.5c3.8 0 5.6 2.2 5.6 5.2l0 1.4c0 0.4-0.2 0.7-0.8 0.7l-8.7 0 0 1.5c0 2.1 1.3 3.5 3.8 3.5 2.1 0 3.3-0.8 4-2.1 0.2-0.3 0.4-0.4 0.7-0.2l0.5 0.3c0.3 0.2 0.4 0.4 0.2 0.7 -0.9 1.6-2.5 2.7-5.4 2.7 -3.8 0-5.4-2.1-5.4-5.1l0-3.3c0-3.3 1.9-5.3 5.6-5.3"
            fill="#3f4d55"/>
        <path
            d="M125 18.2c0.3 0 0.5 0.1 0.5 0.5l0 0.7c0 0.3-0.1 0.5-0.5 0.5l-0.8 0c-1.5 0-2.6 0.3-3.3 0.5 -0.4 0.2-0.5 0.4-0.5 0.7l0 10.1c0 0.4-0.1 0.5-0.5 0.5l-0.8 0c-0.3 0-0.5-0.1-0.5-0.5l0-10.7c0-0.8 0.2-1.1 1-1.4 0.9-0.4 2.3-0.7 4.6-0.8l0.8 0Z"
            fill="#3f4d55"/>
        <path
            d="M131.8 18.2c2.9 0 4.4 1.1 5.1 2.7 0.2 0.3 0.1 0.5-0.2 0.7l-0.6 0.3c-0.4 0.2-0.5 0.1-0.6-0.2 -0.5-1.2-1.4-1.9-3.6-1.9 -2.2 0-3.3 0.8-3.3 2.2 0 2 1.6 2.2 3.8 2.4 2.7 0.3 4.9 0.8 4.9 3.7 0 2.3-1.7 3.9-5.2 3.9 -2.8 0-4.6-1-5.4-3.1 -0.1-0.3 0-0.5 0.3-0.6l0.7-0.2c0.3-0.1 0.5 0 0.6 0.3 0.6 1.6 1.9 2.2 3.9 2.2 2.4 0 3.6-0.8 3.6-2.3 0-1.8-1.5-2.1-3.7-2.3 -2.5-0.3-5.1-0.6-5.1-3.8 0-2.3 1.7-3.8 5-3.8"
            fill="#3f4d55"/>
        <path
            d="M145.2 19.8c-1.3 0-2.3 0.2-3 0.5 -0.5 0.2-0.5 0.3-0.5 0.7l0 8.5c0.6 0.3 2 0.9 3.7 0.9 2.5 0 3.6-1.2 3.6-3.5l0-3.5c0-2.3-1.2-3.5-3.8-3.5m0.2-1.6c3.6 0 5.2 1.8 5.2 5l0 3.6c0 3.2-1.6 5-5.2 5 -1.7 0-3.1-0.6-3.8-0.9l0 5.6c0 0.4-0.1 0.5-0.5 0.5l-0.8 0c-0.4 0-0.5-0.1-0.5-0.5l0-16.3c0-0.8 0.2-1.1 1-1.4 1-0.4 2.4-0.7 4.4-0.7"
            fill="#3f4d55"/>
        <path
            d="M158.7 25.5c-2.7 0-3.7 0.8-3.7 2.5 0 1.6 0.6 2.5 3.6 2.5 1.4 0 2.3-0.2 3.1-0.4 0.4-0.2 0.5-0.3 0.5-0.7l0-3.6c-0.5 0-2-0.2-3.5-0.2m0.1-7.3c4 0 5.1 2.3 5.1 5l0 6.6c0 0.9-0.2 1.1-0.9 1.4 -0.9 0.4-2.3 0.7-4.2 0.7 -4.4 0-5.4-1.4-5.4-3.9 0-2.9 1.6-3.9 5.5-3.9 1.5 0 2.8 0.2 3.3 0.3l0-1.2c0-2-0.6-3.5-3.4-3.5 -1.7 0-2.8 0.7-3.5 1.8 -0.2 0.3-0.3 0.4-0.6 0.2l-0.6-0.3c-0.3-0.1-0.4-0.3-0.3-0.7 0.9-1.7 2.5-2.6 5-2.6"
            fill="#3f4d55"/>
        <path
            d="M172.3 18.2c3.1 0 4.6 1.3 5.2 3.4 0.1 0.4 0 0.5-0.3 0.7l-0.7 0.2c-0.4 0.1-0.5 0-0.6-0.3 -0.5-1.7-1.6-2.5-3.7-2.5 -2.5 0-3.8 1.3-3.8 3.4l0 3.9c0 2.1 1.3 3.4 3.8 3.4 2.1 0 3.3-0.9 3.8-2.6 0.1-0.4 0.3-0.4 0.6-0.3l0.7 0.2c0.3 0.1 0.5 0.3 0.3 0.7 -0.8 2.3-2.4 3.5-5.4 3.5 -3.6 0-5.4-1.9-5.4-5l0-3.8c0-3.1 1.8-5 5.5-5"
            fill="#3f4d55"/>
        <path
            d="M185.7 19.7c-2.6 0-3.9 1.4-3.9 3.7l0 0.9 7.8 0 0-0.9c0-2.2-1.3-3.7-3.9-3.7m0-1.5c3.8 0 5.6 2.2 5.6 5.2l0 1.4c0 0.4-0.2 0.7-0.8 0.7l-8.7 0 0 1.5c0 2.1 1.3 3.5 3.8 3.5 2.1 0 3.3-0.8 4-2.1 0.2-0.3 0.4-0.4 0.7-0.2l0.5 0.3c0.3 0.2 0.4 0.4 0.2 0.7 -0.9 1.6-2.5 2.7-5.4 2.7 -3.8 0-5.4-2.1-5.4-5.1l0-3.3c0-3.3 1.9-5.3 5.6-5.3"
            fill="#3f4d55"/>
        <path d="M24.4 38.8l0 11.3 19.5-15.4 -8.2-4.7 -11.2 8.8Z" fill="#679d30"/>
        <path d="M48 9.6l-3.7 24.5 -8.1-4.7 2.1-14.2 9.7-5.6Z" fill="#679d30"/>
        <path d="M4.2 34.7l19.5 15.4 0-11.3 -11.2-8.8 -8.3 4.7Z" fill="#679d30"/>
        <path d="M3.7 34.1l8.1-4.7 -2.1-14.2 -9.7-5.6 3.7 24.5Z" fill="#679d30"/>
        <path d="M23.7 0l-23.2 9 9.9 5.6 13.3-5.2 0-9.5Z" fill="#679d30"/>
        <path d="M24.4 0l0 9.5 13.3 5.2 9.8-5.6 -23.1-9Z" fill="#679d30"/>
        <path d="M12.5 17.6l1.6 10.9 8.7 6.9 0-11.9 -10.3-5.9Z" fill="#3f4d55"/>
        <path d="M25.2 35.4l8.7-6.9 1.6-10.9 -7.6 4.4 -2.7 1.6 0 11.9Z" fill="#3f4d55"/>
        <path d="M28.3 19.2l6-3.5 -10.3-4.1 -10.3 4.1 2.2 1.3 8 4.6 4.3-2.5Z" fill="#3f4d55"/>
    </svg>)