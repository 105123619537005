import React, {Component} from 'react';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import {setFhemCfgContent} from "../../redux/actions/fhem";
import Select from "react-select";
import MenuItem from "@material-ui/core/MenuItem";
import {selectColourStyles} from "../../constants/ui";
import {fetch} from "../../utils/utils";


class DirectorySelect extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedOption: null
        }
    }

    handleChange = selectedOption => {
        this.setState({selectedOption: selectedOption});

        fetch(this.props.token, '/fhem/content', 'POST', {
            "dir": selectedOption.value
        })
            .then(response => response.json())
            .then(data => {
                this.props.setFhemCfgContent(data)
            })
            .catch(console.log)
    };

    someTest(props) {
        // Fix https://github.com/JedWatson/react-select/issues/3128#issuecomment-439207355
        const {onMouseMove, onMouseOver, ...newInnerProps} = props.innerProps;

        return (
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400,
                }}
                {...newInnerProps}
            >
                {props.children}
            </MenuItem>
        );
    }


    render() {
        const {selectedOption} = this.state;

        const components = {
            Option: this.someTest
        };

        return (
            <Grid item xs>
                <FormControl style={{width: '100%'}}>
                    <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={this.props.directories.map(directory => {
                            return {value: directory, label: directory}
                        })}
                        components={components}
                        styles={selectColourStyles}
                        placeholder={'Verzeichnis auswählen...'}
                    />
                    {/*<NativeSelect*/}
                    {/*value={this.state.selectedOption}*/}
                    {/*name="Directory"*/}
                    {/*onChange={this.handleChange}*/}
                    {/*>*/}
                    {/*{this.props.directories.map(directory => {*/}
                    {/*return <option key={directory} value={directory}>{directory}</option>*/}
                    {/*})}*/}
                    {/*</NativeSelect>*/}
                    {/*<FormHelperText>Directory</FormHelperText>*/}
                </FormControl>
            </Grid>
        );
    }
}

const mapStateToProps = ({authReducer, fhemReducer}) => ({
    directories: fhemReducer.directories,
    token: authReducer.token
})

const mapDispatchToProps = {
    setFhemCfgContent
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectorySelect)
