import {
    SET_CONTROLLERS,
    SET_DEVICES,
    SET_DIRECTORIES,
    SET_FHEMCFGCONTENT,
    SET_RCFHEMCFGCONTENT,
    SET_SYSTEM, SET_UNKNOWNCONTENT, SET_VCCU_ID
} from '../../constants/actions'

const initialState = {
    directories: [],
    fhemCfgContent: '',
    rcFhemCfgContent: '',

    mergedDevices: [],
    mergedControllers: [],
    system: {},
    unknownContent: [],
    vccuID: ''
}

export default (state = initialState, action = {type: undefined}) => {
    const {payload, type} = action;

    switch (type) {
        case SET_DIRECTORIES:
            return {
                ...state,
                directories: payload.data
            }
        case SET_FHEMCFGCONTENT:
            return {
                ...state,
                fhemCfgContent: payload.content
            }
        case SET_RCFHEMCFGCONTENT:
            return {
                ...state,
                rcFhemCfgContent: payload.content
            }
        case SET_DEVICES:
            return {
                ...state,
                mergedDevices: payload.devices
            }
        case SET_CONTROLLERS:
            return {
                ...state,
                mergedControllers: payload.controllers
            }
        case SET_SYSTEM:
            return {
                ...state,
                system: payload.system
            }
        case SET_VCCU_ID:
            return {
                ...state,
                vccuID: payload.vccuID
            }
        case SET_UNKNOWNCONTENT:
            return {
                ...state,
                unknownContent: payload.unknownContent
            }
        default:
            return state;
    }
}