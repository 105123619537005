import {ENABLE_UPLOAD, IS_LOADING, SET_TOAST} from "../../constants/actions";

export const setLoadingState = isLoading => {
    return {
        type: IS_LOADING,
        payload: {
            isLoading
        }
    }
}

export const setToast = toast => {
    return {
        type: SET_TOAST,
        payload: {
            toast
        }
    }
}

export const enableUpload = isEnabled => {
    return {
        type: ENABLE_UPLOAD,
        payload: {
            isEnabled
        }
    }
}