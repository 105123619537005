import _ from 'lodash'
import {SET_LOGINDATA_ACTION} from "../../constants/actions";

let initialState = {
    token: ''
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SET_LOGINDATA_ACTION:
            return _.assign({}, state, {
                token: payload.token
            })
        default:
            return state
    }
}