import React, {Component} from 'react';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import {setMergedControllers} from "../../redux/actions/fhem";
import ControllersDialog from "../Dialog/ControllersDialog";
import TextField from '@material-ui/core/TextField';
import {searchTable} from "../../utils/utils";


const controllerTypeStyle = {
    color: '#a6a6a6'
}


class ControllersTableContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            uploadedControllers: {},
            rcControllers: {},
            vccuID: '',
            search: '',
            globalChecked: true
        }

        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    handleFileRead = (controllerString) => {


        let newControllers = {};

        let lines = controllerString.split('\n').filter(Boolean);
        lines.forEach(line => {
            let splittedLine = line.trim().replace(/  +/g, ' ').split(' ');
            let ip = splittedLine[1];
            let serial = splittedLine[2];
            let id = splittedLine[splittedLine.length - 1];
            let controllerName = id + "_" + serial;

            let controller = 'define ' + controllerName + ' HMUARTLGW ' + ip + '\n' +
                'attr ' + controllerName + ' hmId 45FF96\n' +
                'attr ' + controllerName + ' qLen 100';

            newControllers[controllerName] = {};
            newControllers[controllerName].data = controller;
            newControllers[controllerName].use = true;
            newControllers[controllerName].type = 'Upload'
        });

        let currentControllers = {...this.state.uploadedControllers}
        let controllers = _.assign({}, currentControllers, newControllers);
        this.setState({uploadedControllers: newControllers}, () => {

            this.props.setMergedControllers(_.merge({...this.state.uploadedControllers}, {...this.state.rcControllers}))
        })
    };

    handleCheckboxChange = (context, key, event) => {
        let value = event.target.checked;
        let newObj = {...this.state[context]}
        newObj[key].use = value
        this.setState({[context]: newObj}, () => {
            this.props.setMergedControllers(_.merge({...this.state.uploadedControllers}, {...this.state.rcControllers}))
        })
    };

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.rcFhemCfgContent !== this.props.rcFhemCfgContent) {
            this.getControllerFromRCFhemCfg(nextProps.rcFhemCfgContent)
        }

    }

    getControllerFromRCFhemCfg(content) {

        let splitContent = content.split('\n')
        this.setState({rcControllers: {}})

        //get VCCU ID from RC fhem.cfg
        let vccuLine = (_.find(splitContent, (line => {
            return _.includes(line, 'define VCCU0 CUL_HM');
        }))).split(' ')

        let vccuID = vccuLine[vccuLine.length - 1];
        this.setState({vccuID});

        let hmlansStartIndex = _.indexOf(splitContent, '#### HMLANS ####');
        let i = 0;

        let controllers = {};
        let controllerID = '';

        while (i < splitContent.length) {
            let line = splitContent[i];
            if (line.indexOf('HMUARTLGW') !== -1 && !line.startsWith('#')) {
                controllerID = line.split(' ')[1].split(' ')[0]
                controllers[controllerID] = {data: line, use: true, type: 'RC'}
            } else if (controllerID !== '' && line.indexOf(controllerID) !== -1) {
                controllers[controllerID].data = controllers[controllerID].data.concat('\n', line);
            } else if (controllerID !== '' && line.indexOf(controllerID) === -1) {
                if (controllers[controllerID].data.indexOf('qLen') === -1) {
                    controllers[controllerID].data = controllers[controllerID].data.concat('\n', `attr ${controllerID} qLen 100`);
                }
                controllerID = ''
            }
            i++;
        }

        this.setState({rcControllers: controllers}, () => {
            this.props.setMergedControllers(_.merge({...this.state.uploadedControllers}, {...this.state.rcControllers}))
        })
    }


    handleFileChosen = (files) => {

        this.setState({uploadedControllers: {}})


        Array.from(files).forEach(file => {
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                this.handleFileRead(fileReader)
            }

            fileReader.readAsText(file);
        })


    }

    handleSearchChange(event) {
        this.setState({search: event.target.value})
    }

    globalCheckboxChanged = (event) => {
        let globalChecked = event.target.checked;
        this.setState({globalChecked})

        let rcControllers = {...this.state.rcControllers}
        let uploadedControllers = {...this.state.uploadedControllers}
        Object.keys(rcControllers).forEach(key => {
            rcControllers[key].use = globalChecked
        })
        Object.keys(uploadedControllers).forEach(key => {
            uploadedControllers[key].use = globalChecked
        })

        this.setState({rcControllers, uploadedControllers}, () => {
            this.props.setMergedControllers(_.merge({...this.state.uploadedControllers}, {...this.state.rcControllers}))
        })
    }

    render() {
        let rcControllers = Object.keys(this.state.rcControllers)
        let uploadedControllers = Object.keys(this.state.uploadedControllers)

        return (
            <Grid item xs>
                <Typography variant="h5" gutterBottom style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                    Controller
                    {(rcControllers.length > 0 || uploadedControllers.length > 0) &&
                    <text style={{fontSize: 15, color: 'rgba(0, 0, 0, 0.35)', marginLeft: 10}}>
                        Anzahl Gateway: {rcControllers.length}, Upload: {uploadedControllers.length}
                    </text>}
                </Typography>
                <Paper elevation={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: 150, paddingRight: 0}} align="left">
                                    <div style={{display: 'inline-block'}} className='rows'>
                                        <div className='row' style={{display: 'inline-block'}}>Ausgewählt</div>
                                        <div className='row' style={{display: 'inline-block'}}>
                                            <Checkbox onChange={this.globalCheckboxChanged} checked={this.state.globalChecked}/>
                                        </div>
                                    </div>
                                   </TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="right" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <TextField
                                        id="outlined-search"
                                        placeholder="Suche"
                                        type="search"
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        value={this.state.search}
                                        onChange={this.handleSearchChange}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ControllersDialog handleFileRead={this.handleFileRead}/>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(this.state.rcControllers).filter(key => {
                                return searchTable(this.state.search, this.state.rcControllers[key])
                            }).map(key => (
                                <TableRow key={key}>
                                    <TableCell align="left">
                                        <Checkbox checked={this.state.rcControllers[key].use}
                                                  onChange={event => this.handleCheckboxChange('rcControllers', key, event)}/>
                                    </TableCell>
                                    <TableCell align="left">{key}
                                        <text style={controllerTypeStyle}>(Gateway)</text>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell/>
                                </TableRow>
                            ))}
                            {Object.keys(this.state.uploadedControllers).filter(key => {
                                return searchTable(this.state.search, this.state.uploadedControllers[key])
                            }).map(key => (
                                <TableRow key={key}>
                                    <TableCell align="left">
                                        <Checkbox checked={this.state.uploadedControllers[key].use}
                                                  onChange={event => this.handleCheckboxChange('uploadedControllers', key, event)}/>
                                    </TableCell>
                                    <TableCell align="left">{key}
                                        <text style={controllerTypeStyle}>(Upload)</text>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell/>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        );
    }
}

const mapStateToProps = ({fhemReducer}) => ({
    rcFhemCfgContent: fhemReducer.rcFhemCfgContent
})

const mapDispatchToProps = {
    setMergedControllers
}

export default connect(mapStateToProps, mapDispatchToProps)(ControllersTableContainer)
