import React, {Component} from 'react';
import '../App.css';
import {connect} from "react-redux";
import {getDirectories} from "../redux/actions/fhem";
import Loading from "../components/Loading/Loading";
import Grid from '@material-ui/core/Grid';
import DirectorySelectContainer from "../components/Selects/DirectorySelectContainer";
import RoomcontrolSelectContainer from "../components/Selects/RoomcontrolSelectContainer";
import DevicesTableContainer from "../components/Table/DevicesTableContainer";
import ControllersTableContainer from "../components/Table/ControllersTableContainer";
import Login from "./Login";
import ConfirmGenerateCfg from "../components/Dialog/ConfirmGenerateCfg";


class App extends Component {

    componentDidMount() {
        console.log('asd')
    }

    render() {
        return (
            <div className="App">
                <div id='snackbarhelper'/>
                {this.props.authenticated ?
                    <div>
                        {this.props.isLoading && <Loading/>}
                        <ConfirmGenerateCfg/>
                        < Grid container spacing={24} style={{padding: 30}}>
                            <DirectorySelectContainer/>
                            <RoomcontrolSelectContainer/>
                        </Grid>
                        <Grid container spacing={24} style={{padding: 10}}>
                            <DevicesTableContainer/>
                            <ControllersTableContainer/>
                        </Grid>
                    </div>
                    :
                    <Login/>}
            </div>
        );
    }
}

const mapStateToProps = ({fhemReducer, uiReducer, authReducer}) => ({
    directories: fhemReducer.directories,
    isLoading: uiReducer.isLoading,
    authenticated: authReducer.token !== ''
})

const mapDispatchToProps = {getDirectories}

export default connect(mapStateToProps, mapDispatchToProps)(App);
