/* global Notification */
import React from 'react' // eslint-disable-line no-unused-vars
import {
    GET_FHEMCFGCONTENT,
    GET_RCFHEMCFGCONTENT,
    SEND_DIRECTORIES,
    SEND_ERRORMESSAGE,
    SEND_FHEMCFGCONTENT,
    SEND_INFOMESSAGE,
    SEND_RCFHEMCFGCONTENT,
    SEND_SUCCESSMESSAGE,
    SEND_WARNINGMESSAGE,
    SET_RCFHEMCFGCONTENT
} from '../../constants/actions'
import {setDirectories, setFhemCfgContent, setRCFhemCfgContent, setUnknownContent, setVCCU_ID} from '../actions/fhem'
import {setLoadingState} from "../actions/ui";
import toast from "../../components/Toast/Toast";
import {getVCCU_ID, removeKnownContent} from "../../utils/fhem";

export default store => {
    return next => action => {
        const result = next(action)
        const payload = action.payload
        switch (action.type) {
            case GET_RCFHEMCFGCONTENT: {
                store.dispatch(setLoadingState(true))
                break
            }
            case GET_FHEMCFGCONTENT: {
                store.dispatch(setLoadingState(true))
                break
            }
            case SEND_DIRECTORIES: {
                store.dispatch(setDirectories(payload.directories))
                break
            }
            case SEND_FHEMCFGCONTENT: {
                store.dispatch(setLoadingState(false))
                store.dispatch(setFhemCfgContent(payload.content))
                break
            }
            case SEND_RCFHEMCFGCONTENT: {
                store.dispatch(setLoadingState(false))
                store.dispatch(setRCFhemCfgContent(payload.content))
                break
            }
            case SEND_SUCCESSMESSAGE: {
                toast.success(payload.message);
                break
            }
            case SEND_ERRORMESSAGE: {
                toast.error(payload.message);
                break
            }
            case SEND_INFOMESSAGE: {
                toast.info(payload.message);
                break
            }
            case SEND_WARNINGMESSAGE: {
                toast.warning(payload.message);
                break
            }
            case SET_RCFHEMCFGCONTENT: {

                store.dispatch(setVCCU_ID(getVCCU_ID(payload.content)))
                store.dispatch(setUnknownContent(removeKnownContent(payload.content)))
                break
            }
            default:
                break
        }
        return result
    }
}
