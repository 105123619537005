import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';

export default {
    default: function (msg) {
        this.toast(msg, 'default');
    },
    success: function (msg) {
        this.toast(msg, 'success');
    },
    error: function (msg) {
        this.toast(msg, 'error');
    },
    warning: function (msg) {
        this.toast(msg, 'warning');
    },
    info: function (msg) {
        this.toast(msg, 'info');
    },
    toast: function (msg, variant) {
        const Display = withSnackbar(({ message, enqueueSnackbar }) => {
            enqueueSnackbar(message, { variant });
            return null;
        });
        const mountPoint = document.getElementById('snackbarhelper');
        ReactDOM.render(
            <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
                <Display message={msg} variant={variant} />
            </SnackbarProvider>,
            mountPoint)
    }
}