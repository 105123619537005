import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);


const textAreaStyle = {
    minWidth: 500,
    minHeight: 280,
    border: 'none',
    fontSize: 15,
    fontFamily: 'Roboto'
}

class ControllersDialog extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            open: false,
            text: ''
        };

        this.handleChange = this.handleChange.bind(this)
    }


    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleChange(event) {
        this.setState({text: event.target.value})
    }

    render() {
        return (
            <div>
                <IconButton onClick={this.handleClickOpen}>
                    <AddIcon/>
                </IconButton>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Controller
                    </DialogTitle>
                    <DialogContent>
                        <textarea style={textAreaStyle} name="body"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.text}
                                  placeholder={'Controller eintragen, z.B:\nE4:95:6E:45:77:47  10.8.9.176  PEQ0532208  Betterspace  3821\n'}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.props.handleFileRead(this.state.text)
                            this.handleClose()
                        }} color="primary">
                            Hinzufügen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ControllersDialog)