import fhemMiddleware from './fhem'
import uiMiddleware from './ui'


const middlewares = [
    fhemMiddleware,
    uiMiddleware
]

if (process.env.NODE_ENV === `development`) {
    const {createLogger} = require(`redux-logger`)
    middlewares.push(createLogger({
        // ...options
        duration: true
    }))
}

export default middlewares
