import {applyMiddleware, compose, createStore} from 'redux'
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from 'redux-persist/lib/constants'
import rootReducer from './reducers/rootReducer';
import {persistCombineReducers, persistStore} from 'redux-persist'
import localforage from 'localforage'
import createActionBuffer from 'redux-action-buffer'
import middlewares from './middlewares'
import {START_APP} from "../constants/actions";


// export default function configureStore(initialState = {}) {
//     return createStore(
//         rootReducer,
//         applyMiddleware(thunk)
//     );
// }

export default function configureStore () {

    return new Promise((resolve, reject) => {
        try {

            // use desired middlewares
            const reducers = persistCombineReducers({
                key: 'bs-control-center',
                version: 1,
                storage: localforage,
                whitelist: [
                    'authReducer',
                    '_persist'
                ]
            }, {
                ...rootReducer
            })

            const enhancer = compose(
                applyMiddleware(
                    createActionBuffer({
                        breaker: a => a.type === REHYDRATE,
                        passthrough: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER] // full persist stack
                    }),
                    ...middlewares
                )
            )

            const store = createStore(
                reducers,
                undefined,
                enhancer
            )

            store.dispatch({
                type: START_APP
            })

            const persistor = persistStore(store, {}, () => {
                resolve({store, persistor})
            })

            persistor.purge()

        } catch (e) {
            reject(e)
        }
    })
}
