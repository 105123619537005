import {SET_LOGINDATA_ACTION} from "../../constants/actions";

export const setLogindata = token => {
    return {
        type: SET_LOGINDATA_ACTION,
        payload: {
            token
        }
    }
}

