import React, {Component} from 'react';
import {connect} from "react-redux";
import Button from '@material-ui/core/Button';
import {copyFhemCfgToRC} from "../../redux/actions/fhem";


class GenerateCfg extends Component {

    constructor(props) {
        super(props)
        this.state = {}

    }



    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10}}>
                <Button disabled={!this.props.enableUpload} variant="contained" color="primary"
                        onClick={() => {this.props.handleClickOpen()}} style={{display: 'inline-block'}}>
                    Let's roll
                </Button>
            </div>

        );
    }
}


const mapStateToProps = ({uiReducer}) => ({
    enableUpload: uiReducer.enableUpload
})

const mapDispatchToProps = {
    copyFhemCfgToRC
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateCfg);
