/* global Notification */
import React from 'react' // eslint-disable-line no-unused-vars
import {SET_CONTROLLERS, SET_DEVICES, SET_SYSTEM} from '../../constants/actions'
import _ from 'lodash';
import {enableUpload} from "../actions/ui";

export default store => {
    return next => action => {
        const result = next(action)
        const payload = action.payload
        switch (action.type) {
            case SET_SYSTEM:
            case SET_CONTROLLERS:
            case SET_DEVICES: {
                let fhemReducer = store.getState().fhemReducer
                let isUploadEnabled = !(_.isUndefined(fhemReducer.system.vpn) || _.isEmpty(fhemReducer.mergedControllers) || _.isEmpty(fhemReducer.mergedDevices))
                store.dispatch(enableUpload(isUploadEnabled))
                break
            }
            default:
                break
        }
        return result
    }
}
