import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../css/loading.css'

class Loading extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        return (
            <div className="loading-animation" style={loadingStyle}>
                <div className="animation-brand text-xs-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="cube" viewBox="0 0 55 57" strokeLinejoin="round">
                        <path d="M27.5 43.7l0 12.8 21.9-17.4 -9.3-5.3 -12.6 10Z"/>
                        <path d="M54.1 10.9l-4.1 27.5 -9.1-5.2 2.4-16 10.9-6.3 -0.1 0Z"/>
                        <path d="M4.7 39.1l22 17.4 0-12.7 -12.7-10 -9.3 5.3Z"/>
                        <path d="M4.2 38.5l9.1-5.2 -2.4-16 -10.9-6.3 4.1 27.5 0.1 0Z"/>
                        <path d="M26.7 0.1l-26.1 10.2 11.1 6.4 15-5.8 0-10.6 0-0.1Z"/>
                        <path d="M27.5 0.1l0 10.7 15 5.9 11.1-6.4 -26-10.2 -0.1 0.1Z"/>
                        <path d="M14.2 19.9l1.8 12.3 9.8 7.7 0-13.3 -11.5-6.7Z"/>
                        <path d="M28.4 39.9l9.8-7.7 1.8-12.3 -8.5 5 -3 1.7 0 13.4Z"/>
                        <path d="M31.9 21.7l6.8-3.9 -11.5-4.6 -11.5 4.6 2.6 1.4 9 5.3 4.8-2.8Z"/>
                    </svg>
                </div>
            </div>
        );
    }
}

const loadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
