import {ENABLE_UPLOAD, IS_LOADING, SET_TOAST} from "../../constants/actions";

let initialState = {
    enableUpload: false,
    toast: {
        show: false,
        message: ''
    }
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading: payload.isLoading
            }
        case ENABLE_UPLOAD:
            return {
                ...state,
                enableUpload: payload.isEnabled
            }
        case SET_TOAST:
            return {
                ...state,
                toast: payload.toast
            }
        default:
            return state
    }
}