import {DEVICE_PATTERN_REGEX} from "../constants/fhem";

export const generateFHEMCfg = (devices, controllers, unknownContent, vccuID) => {
    console.log(devices)

    let fhemString = unknownContent.join('\n');

    fhemString += "\n#### HMLANS ####\n";

    let referenceController;

    checkControllerDuplicates(controllers).forEach(key => {
        delete controllers[key];
    })

    for (let key in controllers) {
        getControllerVpnIp(controllers[key].data)
        if (controllers[key].use) {
            fhemString += controllers[key].data + "\n";
            if (!referenceController) {
                referenceController = key;
            }
        }
    }
    ;


    fhemString += "\n#### VCCU ####\n";
    fhemString += createVCCU(controllers, referenceController, vccuID);

    fhemString += "\n#### DEVICES ####\n";
    for (let key in devices) {
        if (devices[key].use) {
            let dev = devices[key].data.replace(new RegExp('hmlan0', 'g'), referenceController);
            console.log(dev)
            fhemString += dev + "\n";
        }
    }

    return fhemString;

}

const checkControllerDuplicates = controllers => {
    let rcVpnIps = [];
    let uploadControllers = [];
    let duplicates = [];

    //sort rc and uploaded controllers
    for (let key in controllers) {
        let controller = controllers[key];
        let vpnIp = getControllerVpnIp(controller.data)
        if (controller.type === 'RC') {
            rcVpnIps.push(vpnIp)
        } else if (controller.type === 'Upload') {
            uploadControllers.push({key, vpnIp})
        }
    }

    //check if rc and uploaded controllers have duplicate VPN IPs
    uploadControllers.forEach(controller => {
        if (rcVpnIps.indexOf(controller.vpnIp) > -1) {
            duplicates.push(controller.key)
        }
    })

    return duplicates
}

const getControllerVpnIp = controllerData => {
    return controllerData.split('HMUARTLGW')[1].split('\n')[0].replace(/ /g, '');
}

const createVCCU = (controllers, referenceController, vccuID) => {

    let ioList = "";

    for (let key in controllers) {
        if (controllers[key].use) {
            ioList += key + ","
        }
    }

    return "define VCCU0 CUL_HM " + vccuID + "\n" +
        "attr VCCU0 IODev " + referenceController + "\n" +
        "attr VCCU0 IOList " + ioList + "\n" +
        "attr VCCU0 model CCU-FHEM\n" +
        "attr VCCU0 subType virtual\n" +
        "attr VCCU0 webCmd virtual:update\n";
}

export const getVCCU_ID = (content) => {

    console.log(content)

    let lines = content.split('\n').filter(Boolean);
    let line = lines.find(l => l.startsWith('define VCCU0 CUL_HM'))
    console.log(line)

    return line.split('define VCCU0 CUL_HM ')[1]
}

export const removeKnownContent = (content) => {
    let lines = content.split('\n').filter(Boolean);
    //remove known devices

    let controllerID = '';

    lines = lines
        .filter(line => !line.match(DEVICE_PATTERN_REGEX))  //filter devices
        .filter(line => {                                   //filter controller
            if ((line.indexOf('HMUARTLGW') !== -1 && !line.startsWith('#')) || (controllerID !== '' && line.indexOf(controllerID) !== -1)) {
                controllerID = line.split(' ')[1].split(' ')[0]
                return false;
            } else if (controllerID !== '' && line.indexOf(controllerID) !== -1) {
                return false;
            } else {
                controllerID = ''
                return true;
            }

        })
        .filter(line => !["VCCU", "#### HMLANS ####", "#### DEVICES ####"].some(substring => line.includes(substring)))   //filter VCCUs and headings

    return lines;
}