import {
    COPY_FHEMCFGTORC,
    GET_DIRECTORIES,
    GET_FHEMCFGCONTENT,
    GET_RCFHEMCFGCONTENT,
    SET_CONTROLLERS,
    SET_DEVICES,
    SET_DIRECTORIES,
    SET_FHEMCFGCONTENT,
    SET_RCFHEMCFGCONTENT,
    SET_SYSTEM, SET_UNKNOWNCONTENT, SET_VCCU_ID
} from '../../constants/actions'

export const getDirectories = () => {
    return {
        type: GET_DIRECTORIES,
    }
}

export const getFhemCfgContent = (name) => {
    return {
        type: GET_FHEMCFGCONTENT,
        payload: {
            name
        }
    }
}

export const getRCFhemCfgContent = (vpnIP) => {
    return {
        type: GET_RCFHEMCFGCONTENT,
        payload: {
            vpnIP
        }
    }
}

export const setDirectories = data => {
    return {
        type: SET_DIRECTORIES,
        payload: {
            data
        }
    }
}

export const setFhemCfgContent = content => {
    return {
        type: SET_FHEMCFGCONTENT,
        payload: {
            content
        }
    }
}

export const setRCFhemCfgContent = content => {
    return {
        type: SET_RCFHEMCFGCONTENT,
        payload: {
            content
        }
    }
}

export const setMergedDevices = devices => {
    console.log('SET_DEVICES', devices)
    return {
        type: SET_DEVICES,
        payload: {
            devices
        }
    }
}

export const setMergedControllers = controllers => {
    return {
        type: SET_CONTROLLERS,
        payload: {
            controllers
        }
    }
}

export const copyFhemCfgToRC = (vpnIP, fileContent) => {
    return {
        type: COPY_FHEMCFGTORC,
        payload: {
            vpnIP,
            fileContent
        }
    }
}

export const setSystem = system => {
    return {
        type: SET_SYSTEM,
        payload: {
            system
        }
    }
}

export const setVCCU_ID = vccuID => {
    return {
        type: SET_VCCU_ID,
        payload: {
            vccuID
        }
    }
}

export const setUnknownContent = unknownContent => {
    return {
        type: SET_UNKNOWNCONTENT,
        payload: {
            unknownContent
        }
    }
}