import React, {Component} from 'react';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from '@material-ui/core/FormControl';
import Client, {RCClient} from '@betterspace/client'
import _ from 'lodash'
import {getRCFhemCfgContent, setRCFhemCfgContent, setSystem} from "../../redux/actions/fhem";
import Select from "react-select";
import {selectColourStyles} from "../../constants/ui";
import {setLogindata} from "../../redux/actions/auth";
import toast from "../Toast/Toast";
import {fetch} from "../../utils/utils";


class RoomcontrolSelect extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sites: [],
            selectedValue: null,
            selectedOption: null,
        }
    }

    componentWillMount() {
        this.fetchSites()
    }

    someTest(props) {
        // Fix https://github.com/JedWatson/react-select/issues/3128#issuecomment-439207355
        const {onMouseMove, onMouseOver, ...newInnerProps} = props.innerProps;

        return (
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                style={{
                    fontWeight: props.isSelected ? 500 : 400
                }}
                {...newInnerProps}
            >
                {props.children}
            </MenuItem>
        );
    }

    fetchSites() {
        const client = new Client({
            token: this.props.token
        })

        client.getSites().then(sites => {
            let filteredSites = sites.filter(site => {
                return !_.isNull(site.GatewayUrl)
            }).map(site => {
                return {uuid: site.Uuid, name: site.Name, url: site.GatewayUrl}
            })

            this.setState({sites: filteredSites})
        }).catch(e => {
            console.log(e)
            this.props.setLogindata('')
        })
    }

    handleChange = selectedOption => {
        const siteUuid = selectedOption.value;
        const siteName = selectedOption.label
        this.setState({selectedOption});
        this.getGatewayVPNIP(siteName, siteUuid)
    };


    getGatewayVPNIP = (siteName, siteUuid) => {
        const rcClient = new RCClient(siteUuid, {
            token: this.props.token
        });

        rcClient.getSystem().then(system => {



            //gateway not reachable
            if (_.isUndefined(system.vpn)) {
                toast.error('Roomcontrol nicht erreichbar!');
            } else {
                system.siteName = siteName;

                fetch(this.props.token, '/fhem/rccontent', 'POST', {
                    "vpnIp": system.vpn
                })
                    .then(response => response.json())
                    .then(data => {
                        this.props.setRCFhemCfgContent(data)
                    })
                    .catch(console.log)
            }
            this.props.setSystem(system)
        }).catch(e => {
            toast.error(`${e.name} ${e.statusCode} Roomcontrol nicht erreichbar?`);
            //this.props.setLogindata('')
        })
    }

    render() {
        const {selectedOption} = this.state;

        const components = {
            Option: this.someTest
        };

        return (
            <Grid item xs>
                <FormControl style={{width: '100%'}}>
                    <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={this.state.sites.map(site => {
                            return {value: site.uuid, label: site.name}
                        })}
                        components={components}
                        styles={selectColourStyles}
                        placeholder={'Hotel auswählen...'}
                    />
                </FormControl>
            </Grid>
        );
    }
}

const mapStateToProps = ({fhemReducer, authReducer}) => ({
    directories: fhemReducer.directories,
    token: authReducer.token
})

const mapDispatchToProps = {
    setSystem,
    setRCFhemCfgContent,
    setLogindata
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomcontrolSelect);
