import React, {Component} from 'react';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import {setMergedDevices} from "../../redux/actions/fhem";
import TextField from '@material-ui/core/TextField';
import {searchTable} from "../../utils/utils";
import {DEVICE_PATTERN_REGEX} from "../../constants/fhem";


const controllerTypeStyle = {
    color: '#a6a6a6'
}

class DevicesTableContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rcFhemCfgContent: {},
            fhemCfgContent: {},
            search: '',
            globalChecked: true
        }

        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.globalCheckboxChanged = this.globalCheckboxChanged.bind(this)
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.rcFhemCfgContent !== this.props.rcFhemCfgContent) {
            this.mergeDevices(nextProps.rcFhemCfgContent, 'rcFhemCfgContent')
        } else if (nextProps.fhemCfgContent !== this.props.fhemCfgContent) {
            this.mergeDevices(nextProps.fhemCfgContent, 'fhemCfgContent')
        }

    }


    mergeDevices(content, key) {

        let devices = {};
        let controller = 'hmlan0';

        let lines = content.split('\n').filter(Boolean);
        lines.forEach(line => {
            let idArr = line.match(DEVICE_PATTERN_REGEX);
            if (idArr) {
                let id = idArr[0];
                if (!devices[id]) {
                    devices[id] = {};
                    devices[id].use = true;
                }
                let device = devices[id].data;
                if (device) {
                    if (line.indexOf('IODev') > -1) {
                        line = 'attr ' + id + ' IODev ' + controller;
                    }
                    if (line.indexOf('IOgrp') > -1) {
                        line = 'attr ' + id + ' IOgrp VCCU0';
                    }
                    if (line.indexOf('serialNr') > -1) {
                        devices[id].serial = line.substring(24, 34);
                    }
                    device += '\n' + line;
                    devices[id].data = device;
                } else {
                    devices[id].data = line;
                }
            }
        })

        this.setState({[key]: devices}, () => {
            this.props.setMergedDevices(_.merge({...this.state.fhemCfgContent}, {...this.state.rcFhemCfgContent}))
        })
    }

    handleCheckboxChange = (context, key, event) => {
        let value = event.target.checked;
        let newObj = {...this.state[context]}
        newObj[key].use = value
        this.setState({[context]: newObj}, () => {
            this.props.setMergedDevices(_.merge({...this.state.fhemCfgContent}, {...this.state.rcFhemCfgContent}))
        })
    };

    handleSearchChange(event) {
        this.setState({search: event.target.value})
    }

    globalCheckboxChanged = (event) => {
        let globalChecked = event.target.checked;
        this.setState({globalChecked})

        let rcFhemCfgContent = {...this.state.rcFhemCfgContent}
        let fhemCfgContent = {...this.state.fhemCfgContent}
        Object.keys(rcFhemCfgContent).forEach(key => {
            rcFhemCfgContent[key].use = globalChecked
        })
        Object.keys(fhemCfgContent).forEach(key => {
            fhemCfgContent[key].use = globalChecked
        })

        this.setState({rcFhemCfgContent, fhemCfgContent}, () => {
            this.props.setMergedDevices(_.merge({...this.state.fhemCfgContent}, {...this.state.rcFhemCfgContent}))
        })
    }

    render() {
        let rcFhemCfgContent = Object.keys(this.state.rcFhemCfgContent)
        let fhemCfgContent = Object.keys(this.state.fhemCfgContent)

        return (
            <Grid item xs>
                <Typography variant="h5" gutterBottom style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                    Geräte
                    {(rcFhemCfgContent.length > 0 || fhemCfgContent.length > 0) &&
                    <text style={{fontSize: 15, color: 'rgba(0, 0, 0, 0.35)', marginLeft: 10}}>
                        Anzahl Gateway: {rcFhemCfgContent.length}, Directory: {fhemCfgContent.length}
                    </text>}
                </Typography>

                <Paper elevation={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: 150, paddingRight: 0}} align="left">
                                    <div style={{display: 'inline-block'}} className='rows'>
                                        <div className='row' style={{display: 'inline-block'}}>Ausgewählt</div>
                                        <div className='row' style={{display: 'inline-block'}}>
                                            <Checkbox onChange={this.globalCheckboxChanged} checked={this.state.globalChecked}/>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        id="outlined-search"
                                        placeholder="Suche"
                                        type="search"
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        value={this.state.search}
                                        onChange={this.handleSearchChange}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(this.state.rcFhemCfgContent).filter(key => {
                                return searchTable(this.state.search, this.state.rcFhemCfgContent[key])
                            }).map(key => (
                                <TableRow key={key}>
                                    <TableCell align="left">
                                        <Checkbox checked={this.state.rcFhemCfgContent[key].use}
                                                  onChange={event => this.handleCheckboxChange('rcFhemCfgContent', key, event)}/>
                                    </TableCell>
                                    <TableCell align="left">{key}
                                        <text style={controllerTypeStyle}> {this.state.rcFhemCfgContent[key].serial}</text>
                                        <div style={controllerTypeStyle}>(Gateway)</div>
                                    </TableCell>
                                    <TableCell/>
                                </TableRow>
                            ))}
                            {Object.keys(this.state.fhemCfgContent).filter(key => {
                                return searchTable(this.state.search, this.state.fhemCfgContent[key])
                            }).map(key => (
                                <TableRow key={key}>
                                    <TableCell align="left">
                                        <Checkbox checked={this.state.fhemCfgContent[key].use}
                                                  onChange={event => this.handleCheckboxChange('fhemCfgContent', key, event)}/>
                                    </TableCell>
                                    <TableCell align="left">{key}
                                        <text style={controllerTypeStyle}> {this.state.fhemCfgContent[key].serial}</text>
                                        <div style={controllerTypeStyle}>(Directory)</div>
                                    </TableCell>
                                    <TableCell/>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        );
    }
}

const mapStateToProps = ({fhemReducer}) => ({
    rcFhemCfgContent: fhemReducer.rcFhemCfgContent,
    fhemCfgContent: fhemReducer.fhemCfgContent
})

const mapDispatchToProps = {
    setMergedDevices
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesTableContainer)
