import React, {Component} from 'react' // eslint-disable-line no-unused-vars
import {connect} from 'react-redux'
import {Button, Grid, LinearProgress, Paper, Snackbar, TextField} from '@material-ui/core'
import {BetterspaceIcon} from "../constants/ui";
import {login, setLogindata} from "../redux/actions/auth";
import Client from '@betterspace/client';
import toast from '../components/Toast/Toast'
import {getDirectories, setDirectories} from "../redux/actions/fhem";
import {fetch} from "../utils/utils";


class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: 's.gries@betterspace.de',
            password: 'betterspace',
            isLoading: false,
            openSnackbar: false
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit() {
        this.setState({isLoading: true})
        const client = new Client({
            username: this.state.email,
            password: this.state.password
        })
        client.getToken()
            .then(token => {
                this.props.setLogindata(token)

                fetch(token, '/fhem/directories', 'POST', {
                    "vpnIp": "10.8.2.111"
                })
                    .then(response => response.json())
                    .then(data => {
                        this.props.setDirectories(data.split("\n"))
                    })
                    .catch(console.log)


                this.setState({isLoading: false})
            }).catch(e => {
            console.log(e)
            if (e.Status === 'ERROR' || e.statusCode === 500) {
                toast.error('Benutzername oder Passwort sind ungültig.');
                this.setState({isLoading: false});
            }
        })
    }

    render() {
        let {email, password} = this.state
        return (
            <div>
                {this.state.isLoading && <LinearProgress style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0
                }}/>}
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30}}>
                    <BetterspaceIcon/>
                </div>
                <Grid container justify="center" style={{paddingTop: 60, height: '100%'}}>
                    <Paper children={<div style={{padding: 20, width: 300}}>
                        <div>
                            <TextField fullWidth placeholder={'Benutzername'} value={email}
                                       onChange={e => this.setState({email: e.target.value})}
                                       onKeyPress={e => {
                                           if (e.key === 'Enter') {
                                               this.onSubmit()
                                           }
                                       }}/>
                        </div>
                        <div style={{marginTop: 20}}>
                            <TextField type="password" fullWidth placeholder={'Passwort'}
                                       onChange={e => this.setState({password: e.target.value})} value={password}
                                       onKeyPress={e => {
                                           if (e.key === 'Enter') {
                                               this.onSubmit()
                                           }
                                       }}/>
                        </div>
                        <div style={{marginTop: 40}}>
                            <form>
                                <Button variant="contained" color='primary' onClick={this.onSubmit} fullWidth>
                                    Login YO
                                </Button>
                            </form>
                        </div>

                    </div>
                    }/>
                </Grid>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={this.state.openSnackbar}
                    onClose={() => {
                        this.setState({openSnackbar: false})
                    }}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Ungültiger Benutzername oder Passwort</span>}
                />
            </div>
        )
    }
}

const mapStateToProps = ({}) => {
    return {}
}

const mapDispatchToProps = {
    setLogindata,
    getDirectories,
    setDirectories
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
