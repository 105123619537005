import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// Redux Store
import {Provider} from 'react-redux'
import configureStore from './redux/store';
import {PersistGate} from 'redux-persist/lib/integration/react'
import App from "./screens/App";
import MuiThemeProvider from "@material-ui/core/es/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/es/styles/createMuiTheme";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#679d2e'
        },
        secondary: {
            main: '#679d2e'
        },
    }
})

function init() {
    //start rendering when rehydration is finished
    configureStore().then(({store, persistor}) => {
        ReactDOM.render(
            <Provider store={store}>
                <PersistGate
                    persistor={persistor}>
                    <MuiThemeProvider theme={theme}>


                            <App/>

                    </MuiThemeProvider>
                </PersistGate>
            </Provider>,
            document.getElementById('root')
        )
    }).catch(console.log)
}

init();
