import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import toast from "../Toast/Toast";
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import GenerateCfg from "../Button/GenerateCfg";
import {generateFHEMCfg} from "../../utils/fhem";
import {copyFhemCfgToRC} from "../../redux/actions/fhem";
import {fetch} from "../../utils/utils";


class ConfirmGenerateCfg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }

        this.onYeClicked = this.onYeClicked.bind(this)
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    onYeClicked() {
        let fhemString = generateFHEMCfg(this.props.mergedDevices, this.props.mergedControllers, this.props.unknownContent, this.props.vccuID)


        fetch(this.props.token, '/fhem/copy', 'POST', {
            "vpnIp": this.props.vpnIp,
            "fileContent": fhemString
        })
            .then(response => {
                if(typeof response === 'object') {
                    return response
                }else {
                    return response.json();
                }
            })
            .then(data => {
                console.log(data)
                toast.success("fhem.cfg wurde erfolgreich generiert und kopiert")
            })
            .catch((err) => {
                console.log(err)
                toast.error("Fehler beim Generieren/Kopieren der fhem.cfg")
            })


        //this.props.copyFhemCfgToRC(this.props.vpnIp, fhemString)
        this.handleClose()
    }

    render() {
        const {fullScreen} = this.props;

        return (
            <div>
                <GenerateCfg handleClickOpen={() => this.handleClickOpen()}/>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Geräte und Controller verschieben?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Die ausgewählten Geräte und Controller werden nach {this.props.siteName} verschoben. Das
                            Hotel wird danach neugestartet.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Nah
                        </Button>
                        <Button onClick={this.onYeClicked} color="primary" autoFocus>
                            Ye
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({fhemReducer, authReducer}) => ({
    siteName: fhemReducer.system.siteName,
    mergedControllers: fhemReducer.mergedControllers,
    mergedDevices: fhemReducer.mergedDevices,
    unknownContent: fhemReducer.unknownContent,
    vccuID: fhemReducer.vccuID,
    vpnIp: fhemReducer.system.vpn,
    token: authReducer.token
})


const mapDispatchToProps = {
    copyFhemCfgToRC
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmGenerateCfg);