const nodefetch = require('node-fetch');

export const multiIncludes = (text, values) => {
    let re = new RegExp(values.join('|').toLowerCase());
    return re.test(text);
}

export const searchTable = (searchString, obj) => {
    return multiIncludes(JSON.stringify(obj).toLowerCase(), searchString.split(' '))
}


export const fetch = (token, path, method, body) => {
    return nodefetch(`https://task-server.betterspace.io${path}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'AuthToken': token,
        },
        body: JSON.stringify(body)
    })
}