//auth
export const SET_LOGINDATA_ACTION = 'SET_LOGINDATA_ACTION'


// SHARED
export const START_APP = 'START_APP'
export const DATA_GATEWAYS = 'DATA_GATEWAYS'
export const DATA_SITES = 'DATA_SITES'

export const UPDATE_START = 'UPDATE_START'
export const UPDATE_STARTED = 'UPDATE_STARTED'
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
export const UPDATE_FAILED = 'UPDATE_FAILED'

export const OGEMA_RESTART = 'OGEMA_RESTART'
export const OGEMA_RESTARTED = 'OGEMA_RESTARTED'
export const OGEMA_SUCCESS = 'OGEMA_SUCCESS'
export const OGEMA_FAILED = 'OGEMA_FAILED'

export const GATEWAY_RESTART = 'GATEWAY_RESTART'
export const GATEWAY_RESTARTED = 'GATEWAY_RESTARTED'
export const GATEWAY_SUCCESS = 'GATEWAY_SUCCESS'
export const GATEWAY_FAILED = 'GATEWAY_FAILED'
export const GATEWAY_LINK = 'GATEWAY_LINK'
export const CONFIRM_GATEWAY_LINK = 'CONFIRM_GATEWAY_LINK'

export const RESTART_OGEMA = 'RESTART_OGEMA'
export const RESTART_GATEWAY = 'RESTART_GATEWAY'

export const CREATE_GATEWAY = 'CREATE_GATEWAY'
export const CONFIRM_CREATE_GATEWAY = 'CONFIRM_CREATE_GATEWAY'
export const CREATE_GATEWAY_SUCCESS = 'CREATE_GATEWAY_SUCCESS'
export const CREATE_GATEWAY_FAILED = 'CREATE_GATEWAY_FAILED'
export const CREATE_START_GATEWAY = 'CREATE_START_GATEWAY'
export const CREATE_END_GATEWAY = 'CREATE_END_GATEWAY'

export const GET_DIRECTORIES = 'GET_DIRECTORIES'
export const SEND_DIRECTORIES = 'SEND_DIRECTORIES'
export const SET_DIRECTORIES = 'SET_DIRECTORIES'
export const CREATE_FHEMCFG = 'CREATE_FHEMCFG'

export const SET_UNKNOWNCONTENT = 'SET_UNKNOWNCONTENT'

export const GET_FHEMCFGCONTENT = 'GET_FHEMCFGCONTENT'
export const SEND_FHEMCFGCONTENT = 'SEND_FHEMCFGCONTENT'
export const SET_FHEMCFGCONTENT = 'SET_FHEMCFGCONTENT'

export const COPY_FHEMCFGTORC = 'COPY_FHEMCFGTORC'

export const SET_DEVICES = 'SET_DEVICES'
export const SET_CONTROLLERS = 'SET_CONTROLLERS'


export const SET_SYSTEM = 'SET_SYSTEM'

export const SET_VCCU_ID = 'SET_VCCU_ID'

export const IS_LOADING = 'IS_LOADING'

export const ENABLE_UPLOAD = 'ENABLE_UPLOAD'

export const SET_TOAST = 'SET_TOAST'


export const GET_RCFHEMCFGCONTENT = 'GET_RCFHEMCFGCONTENT'
export const SEND_RCFHEMCFGCONTENT = 'SEND_RCFHEMCFGCONTENT'
export const SET_RCFHEMCFGCONTENT = 'SET_RCFHEMCFGCONTENT'

export const GET_ZOHOLOCATIONS = 'GET_ZOHOLOCATIONS'
export const SEND_ZOHOLOCATIONS = 'SEND_ZOHOLOCATIONS'
export const SET_ZOHOLOCATIONS = 'SET_ZOHOLOCATIONS'

export const LINK_SITEANDZOHOLOCATIONS = 'LINK_SITEANDZOHOLOCATIONS'

export const CREATE_CONTROLLERCERTPOOLITEM = 'CREATE_CONTROLLERCERTPOOLITEM'

export const CREATE_GLOBAL_NOTIFICATION = 'CREATE_GLOBAL_NOTIFICATION'
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION'

// CLIENT
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const NEW_TOKEN = 'NEW_TOKEN'

export const WEBSOCKET_OPENING = 'WEBSOCKET_OPENING'
export const WEBSOCKET_OPEN = 'WEBSOCKET_OPEN'
export const WEBSOCKET_CLOSE = 'WEBSOCKET_CLOSE'
export const WEBSOCKET_ERROR = 'WEBSOCKET_ERROR'

export const TOAST_SHOW = 'TOAST_SHOW'
export const TOAST_CLOSE = 'TOAST_CLOSE'

export const UPDATE_SELECTED_SITES = 'UPDATE_SELECTED_SITES'

export const SELECTED_SITES_SET_FILTER = 'SELECTED_SITES_SET_FILTER'
export const SELECTED_SITES_SHOW_SITE = 'SELECTED_SITES_SHOW_SITE'
export const SELECTED_SITES_CHANGE = 'SELECTED_SITES_CHANGE'

export const QUERY_STRING_SET = 'QUERY_STRING_SET'
export const QUERY_STRING_READ = 'QUERY_STRING_READ'


export const SET_FILTER = 'SET_FILTER'
export const SELECT_FILTER = 'SELECT_FILTER'
export const FILTER_SITES = 'FILTER_SITES'

export const SEND_SUCCESSMESSAGE = 'SEND_SUCCESSMESSAGE'
export const SEND_ERRORMESSAGE = 'SEND_ERRORMESSAGE'
export const SEND_INFOMESSAGE = 'SEND_INFOMESSAGE'
export const SEND_WARNINGMESSAGE = 'SEND_WARNINGMESSAGE'

// async Actions
export const GET_FILTER_STARTED = 'GET_FILTER_STARTED'
export const GET_FILTER_RECEIVE = 'GET_FILTER_RECEIVE'

export const SET_FILTER_STARTED = 'SET_FILTER_STARTED'
export const SET_FILTER_RECEIVE = 'SET_FILTER_RECEIVE'

export const ENABLE_FILTER_STARTED = 'ENABLE_FILTER_STARTED'
export const ENABLE_FILTER_RECEIVE = 'ENABLE_FILTER_RECEIVE'

export const DISABLE_FILTER_STARTED = 'DISABLE_FILTER_STARTED'
export const DISABLE_FILTER_RECEIVE = 'DISABLE_FILTER_RECEIVE'

export const DELETE_FILTER_STARTED = 'DELETE_FILTER_STARTED'
export const DELETE_FILTER_RECEIVE = 'DELETE_FILTER_RECEIVE'

/*
  thunk -> call
  *_STARTED start*
  *_RECEIVE receive*
  *_FAILURE failure*
  */

// SERVER
